import Cost from '@/components/inputs/Cost/Cost.vue'
import Date from '@/components/inputs/Date/Date.vue'
import Memo from '@/components/inputs/Memo/Memo.vue'
import FullScreenLoader from '@/components/FullScreenLoader/FullScreenLoader.vue'
import AssetExtraField from '@/components/Asset/AssetForm/AssetExtraField/AssetExtraField.vue'
import AssigneeSearcher from '@/components/inputs/AssigneeSearcher/AssigneeSearcher.vue'
import Scanner from '@/components/Scanner/Scanner.vue'
import AssetMixin from '@/mixins/asset.mixin'
import { VueTelInputVuetify } from 'vue-tel-input-vuetify'
import VueCountryDropdown from 'vue-country-dropdown'
import { mapMutations } from 'vuex'
import { mapGetters } from 'vuex'

const requiredRule = (v) => !!v || 'Required'
const preventWhiteSpaceRule = (v) => (v && !/\s/.test(v)) || 'White space is not allowed.'

export default {
  props: ['id', 'discoveryDevice'],
  mixins: [AssetMixin],
  components: {
    AssetExtraField,
    Cost,
    Date,
    Memo,
    FullScreenLoader,
    AssigneeSearcher,
    Scanner,
    VueTelInputVuetify,
    VueCountryDropdown
  },
  data() {
    return {
      pageTitle: 'RAMS',
      validating: false,
      submitting: false,
      asset: {
        office: null,
        region: null,
        regionRules: [requiredRule],
        countryIsoCode2: null,
        company: null,
        companyRules: [],
        name: null,
        status: null,
        statusRules: [requiredRule],
        operationStatus: null,
        operationStatusRules: [requiredRule],
        purchaseCurrencyCode: null,
        purchaseCost: null,
        purchaseCostRules: [(v) => (v >= 0 && v <= 9999999.99) || 'Out of range'],
        model: null,
        modelRules: [requiredRule],
        purchaseDate: null,
        purchaseDateRules: [(v) => v < this.asset.warrantyExpires || !this.asset.warrantyExpires || 'Must be before Warranty Expiration Date'],
        warrantyExpires: null,
        warrantyExpiresRules: [
          (v) => !v || !!this.asset.purchaseDate || 'Fill in Purchase Date first',
          (v) => !v || !this.asset.purchaseDate || v > this.asset.purchaseDate || 'Must be beyond Purchase Date'
        ],
        serialNumber: null,
        docomoSerialNumber: null,
        memo: null,
        // ITINVM-507 - temp solution for return work form
        memoRules: [
          requiredRule,
          (v) => {
            if (!v) {
              v = ''
            }
            const m1 = v.match(/1\.\sLink\sof\sReturn\sWork\sForm:\s*(.*)\n/)
            if (!m1) {
              return 'Link of return work form is required.'
            }
            if (!this.isValidUrl(m1[1])) {
              return 'Link of return work form is not a valid URL.'
            }
            const m2 = v.match(/2\.\sName\sof\srequester:\s*(.*)(\n|$)/)
            if (!m2 || !m2[1]) {
              return 'Name of requester is required.'
            }
            return true
          }
        ],
        costCode: null,
        costCodeRules: [
          (v) => {
            if (!v) {
              return true
            }
            if (!/^\d{5}$|^(\d|[A-Z]){10}$/.test(v)) {
              return 'Cost code must be either 5 or 10 characters long. For 5 characters cost code it should only contain digits. For 10 characters cost code it could contain digits and capital letter.'
            }
            return true
          }
        ],
        assigneeId: null,
        primaryUserId: null,
        manufacturerId: null,
        extraFields: {}
      },
      locations: [],
      companies: [],
      simSlots: [],
      assets: [],
      whereConditions: null,
      errorMessages: {
        name: [],
        serialNumber: [],
        model: []
      },
      showScannerForAssetName: false,
      scannedAssetName: null,
      showScannerForSerialNumber: false,
      scannedSerialNumber: null,
      manufacturer: null,
      allowEditSerialNumber: false,
      allowEditAssignee: true
    }
  },
  computed: {
    formOperationStatuses() {
      return _.filter(this.operationStatuses, { status: this.asset.status })
    },
    formPclStatuses() {
      return _.filter(this.pclStatuses, { status: this.asset.status })
    },
    nameRules() {
      let rules = [requiredRule, (v) => (v && v.length <= 64) || 'Exceeds maximum length']
      if (this.currentType.graphTypeName !== 'Sim') {
        rules.push(preventWhiteSpaceRule)
      }

      return rules
    },
    serialNumberRules() {
      let rules = [(v) => !!this.asset.model || 'Select Model first']

      if (!this.ability.can('update', 'assetWithoutSerialNumber')) {
        rules.push(requiredRule)
        rules.push(preventWhiteSpaceRule)
        let otherRules = [(v) => (v && v.length <= 64) || 'Exceeds maximum length']
        rules.concat(otherRules)
      }

      if (this.currentType.graphTypeName === 'Sim') {
        let simRules = [
          (v) => (v && /^\d+$/.test(v)) || 'ICCID should only contains digits, the last character is a check digit and should be ignored.',
          (v) => (v && v.length <= 19) || 'ICCID is maximum 19 characters long'
        ]
        return rules.concat(simRules)
      } else {
        return rules
      }
    },
    docomoSerialNumberRules() {
      // allowed first 2 characters
      const set1 = ['DN', 'GE', 'AX', 'TS', 'GD']

      return [
        (v) => !v || v.length === 15 || 'Docomo serial number should be 15 characters long',
        (v) => !v || set1.includes(v.substring(0, 2)) || `The first 2 characters must be one of ${set1.toString()}`,
        (v) => !v || /^[0-6]$/.test(v.substring(5, 6)) || 'The 6th character must be a digit between 0 - 6',
        (v) => !v || /^[0-9]+$/.test(v.substring(6, 14)) || 'The 7th to 14th characters should only contain number'
      ]
    },
    loading() {
      return this.$apollo.queries.getAsset.loading
    },
    title() {
      const type = _.get(this.$route, 'params.type')
      return type ? type.charAt(0).toUpperCase() + type.slice(1) : ''
    },
    defaultCurrencyCode() {
      if (this.asset.region && this.asset.region.defaultCurrencyCode) {
        return this.asset.region.defaultCurrencyCode
      } else {
        return 'JPY'
      }
    },
    loaderMessage() {
      return this.loading ? 'Loading asset' : this.submitting ? 'Submitting' : ''
    },
    allowedRegions() {
      return this.regions.filter((r) =>
        this.ability.can('create', {
          __typename: this.currentType.graphTypeName,
          region: r.text.toUpperCase()
        })
      )
    },
    onEditMode() {
      return !_.isEmpty(this.id)
    },
    manufacturers() {
      return [...new Set(this.models.map((m) => m.manufacturer.name))]
    },
    filteredModels() {
      return this.manufacturer ? this.models.filter((m) => m.manufacturer.name === this.manufacturer) : this.models
    },
    // ITINVM-507 - temp solution for return work form
    isMemoRequired() {
      const targetStatuses = ['DECOMMISSIONED', 'LOST', 'HOLD_HR', 'HOLD_LEGAL', 'HOLD_TERMINATION', 'HOLD_DECOMMISSION', 'HOLD_MALWARE']
      return _.get(this, 'asset.region.text') === 'Americas' && targetStatuses.includes(this.asset.status)
    },
    ...mapGetters('user', ['user'])
  },
  watch: {
    'asset.docomoSerialNumber': {
      handler(value) {
        const iccid = this.convertDocomoSerialNumberToIccid(value)
        if (iccid !== null) {
          this.asset.serialNumber = iccid
          this.validateSerialNumber()
        }
      }
    },
    'asset.region': {
      handler() {
        // clear docomo serial number when region changes
        this.asset.docomoSerialNumber = null
      },
      deep: true
    },
    'asset.model': {
      handler(model) {
        if (model) {
          this.asset.manufacturerId = model.manufacturer.id
          // trigger serial number validation
          if (this.asset.serialNumber) {
            this.$nextTick(this.$refs.serialNumber.focus)
            this.$nextTick(this.$refs.serialNumber.blur)
          }
          // update the manufacturer
          this.manufacturer = model.manufacturer.name
        }
      }
    },
    'asset.purchaseDate': {
      handler() {
        this.$nextTick(this.$refs.warrantyExpires.$children[0].$children[0].validate)
      }
    },
    'asset.warrantyExpires': {
      handler() {
        this.$nextTick(this.$refs.purchaseDate.$children[0].$children[0].validate)
      }
    },
    scannedAssetName(v) {
      if (v) {
        this.asset.name = v
        // trigger validation
        if (this.asset.name) {
          this.$nextTick(this.$refs.name.focus)
          this.$nextTick(this.$refs.name.blur)
        }
      }
    },
    scannedSerialNumber(v) {
      if (v) {
        this.asset.serialNumber = v
        // trigger validation
        if (this.asset.serialNumber) {
          this.$nextTick(this.$refs.serialNumber.focus)
          this.$nextTick(this.$refs.serialNumber.blur)
        }
      }
    },
    manufacturer() {
      if (_.get(this.asset, 'model.manufacturer.name') !== this.manufacturer) {
        this.asset.model = null
      }
    },
    isMemoRequired(v) {
      if (v) {
        if (this.asset.memo === null) {
          this.asset.memo = ''
        }
        const m1 = this.asset.memo.match(/1\.\sLink\sof\sReturn\sWork\sForm:\s*(.*)\n2\.\sName\sof\srequester:\s*(.*)(\n|$)/)
        if (!m1) {
          if (this.asset.memo) {
            this.asset.memo += '\n\n'
          }
          this.asset.memo += '1. Link of Return Work Form: \n2. Name of requester: '
        }
      }
    }
  },
  methods: {
    async validateField(value, path, additionalRules = []) {
      this.whereConditions = [{ path, comparison: 'equal', value }]
      if (this.id) {
        this.whereConditions.push({
          path: 'id',
          comparison: 'equal',
          value: this.id,
          negate: true
        })
      }

      // check other conditions if they have value
      for (let rule of additionalRules) {
        if (rule.value) {
          this.whereConditions.push(rule)
        } else {
          // reset conditions when at least one of the conditions does not have value
          this.whereConditions = []
          let rulePath = null
          if (rule.path === 'model.manufacturer.id') {
            if (this.currentType.singleQueryName === 'sim') {
              rulePath = 'Carrier'
            } else {
              rulePath = 'Manufacturer'
            }
          }
          this.errorMessages[path].push(rulePath + ' not selected')
        }
      }

      // include only non-deleted assets
      this.whereConditions.push({
        path: 'isDeleted',
        comparison: 'equal',
        value: 'false',
        negate: false
      })

      if (!_.isEmpty(this.whereConditions)) {
        const { data, error } = await this.$apollo.query({
          // Query
          query: this.$gql`
                  query assets($where: [WhereExpression]) {
                    ${this.currentType.listQueryName}(where: $where) {
                      totalCount
                    }
                  }
                `,
          variables: { where: this.whereConditions }
        })

        if (data && data[this.currentType.listQueryName]) {
          const assets = data[this.currentType.listQueryName]
          const x = _.find(this.currentType.fields, (x) => x.name === this.whereConditions[0].path).displayName || this.whereConditions[0].path
          const msg = `Asset with the same ${x} exists`
          if (assets.totalCount > 0) {
            this.errorMessages[this.whereConditions[0].path].push(msg)
          } else {
            this.errorMessages[this.whereConditions[0].path] = _.remove(this.errorMessages[this.whereConditions[0].path], msg)
          }
        } else if (error) {
          this.graphQLOnError(`Failed to validate asset data from server. ${error.toString()}`)
          this.assets = {}
        } else {
          this.graphQLOnError('Failed to connect to server.')
          this.assets = {}
        }
      }
    },
    async validateName() {
      if (this.asset.name) {
        await this.validateField(this.asset.name, 'name')
      }
    },
    async validateSerialNumber() {
      if (!_.isEmpty(this.asset.serialNumber)) {
        await this.validateField(this.asset.serialNumber, 'serialNumber', [
          {
            path: 'model.manufacturer.id',
            comparison: 'equal',
            value: this.asset.manufacturerId
          }
        ])
      }
    },
    async validate() {
      this.validating = true

      // validate
      await this.validateName()
      await this.validateSerialNumber()
      if (this.currentType.singleQueryName === 'sim') {
        await this.validatePhoneNumber(this.$refs.name.phone, this.$refs.name.phoneObject)
      }
      const isValid = this.$refs.form.validate() && _.every(this.errorMessages, (o) => o.length === 0)
      this.validating = false
      if (isValid) {
        this.submit()
      }
    },
    async validatePhoneNumber(number, phone) {
      if (this.loading) {
        return
      }
      this.errorMessages.name = []
      if (phone.isValid) {
        await this.validateField(this.asset.name.replaceAll(/\s|-/g, ''), 'name', [
          {
            path: 'status',
            comparison: 'equal',
            value: 'Decommissioned',
            negate: true
          }
        ])
      } else {
        if (this.validating) {
          if (number === '+'.concat(phone.country.dialCode)) {
            this.errorMessages.name.push('Required')
          }

          if (number) {
            this.errorMessages.name.push('Invalid phone number')
          }
        } else {
          if (!this.validating && number && number.replace('+', '') !== phone.country.dialCode) {
            this.errorMessages.name.push('Invalid phone number')
          }
        }
      }
    },
    updatePageTitle() {
      if (this.id) {
        this.pageTitle = `Edit ${_.startCase(this.currentType.singleQueryName)} | RAMS`
      } else {
        this.pageTitle = `Create ${_.startCase(this.currentType.singleQueryName)} | RAMS`
      }
    },
    submit() {
      // remove errors
      this.removeErrorByType('form')

      if (this.currentType.singleQueryName === 'sim') {
        this.asset.name = this.$refs.name.phoneObject.number.international
      }

      // prepare payload
      let input = {
        name: this.asset.name ? this.asset.name.trim() : null,
        status: this.asset.status ? this.asset.status.trim() : null,
        operationStatus: this.asset.operationStatus ? this.asset.operationStatus.trim() : null,
        serialNumber: this.asset.serialNumber ? this.asset.serialNumber.trim() : null,
        purchaseCost: this.asset.purchaseCost,
        purchaseCurrencyCode: this.asset.purchaseCurrencyCode ? this.asset.purchaseCurrencyCode.trim() : null,
        purchaseDate: this.asset.purchaseDate,
        warrantyExpires: this.asset.warrantyExpires,
        officeId: _.get(this.asset, 'office.id') || null,
        companyId: _.get(this.asset, 'company.id'),
        modelId: this.asset.model ? this.asset.model.id.trim() : null,
        assigneeId: this.asset.assigneeId ? this.asset.assigneeId : null,
        memo: this.asset.memo ? this.asset.memo.trim() : null,
        costCode: this.asset.costCode ? this.asset.costCode.trim() : null,
        region: this.asset.region.text.toUpperCase(),
        countryIsoCode2: this.asset.countryIsoCode2
      }

      // add extra fields
      switch (this.currentType.singleQueryName) {
        case 'computer':
          input['alternativeName'] = this.asset.extraFields['alternativeName'] || null
          input['pclStatus'] = this.asset.extraFields['pclStatus'] || null
          input['isLegalHold'] = this.asset.extraFields['isLegalHold'] || false
          input['usage'] = this.asset.extraFields['usage'] || null
          if (input['usage'] === 'SHARED') {
            input['shareStatus'] = this.asset.extraFields['shareStatus'] || null
          } else {
            input['shareStatus'] = null
          }
          input['pcType'] = this.asset.extraFields['pcType'] || null
          input['pcSpecification'] = this.asset.extraFields['pcSpecification'] || null
          input['initialDeploymentDate'] = this.asset.extraFields['initialDeploymentDate']
          input['procurementRequestCode'] = this.asset.extraFields['procurementRequestCode']
          break
        case 'mobile':
          const simSlotsInput = []
          this.asset.extraFields['simSlots'].forEach((x) => {
            const slot = { iMEI: x.iMEI, simId: _.get(x, 'sim.id') }

            // add id only if id exists
            if (!_.isEmpty(x.id)) {
              slot.id = x.id
            }

            // skip simslots without IMEI
            if (!_.isEmpty(slot.iMEI)) {
              simSlotsInput.push(slot)
            }
          })
          input['simSlots'] = simSlotsInput
          input['initialDeploymentDate'] = this.asset.extraFields['initialDeploymentDate']
          break
        case 'sim':
          input['docomoSerialNumber'] = this.asset.docomoSerialNumber
          input['contractStartDate'] = this.asset.extraFields['contractStartDate']
          input['contractEndDate'] = this.asset.extraFields['contractEndDate']
          input['viberNumber'] = this.asset.extraFields['viberNumber']
          input['mailAddress'] = this.asset.extraFields['mailAddress']
          input['noticeMailAddress'] = this.asset.extraFields['noticeMailAddress']
          input['useType'] = this.asset.extraFields['useType'] || null
          input['rMobileSupportId'] = this.asset.extraFields['rMobileSupportId']
          input['rMobileSupportPasswordVersion'] = this.asset.extraFields['rMobileSupportPasswordVersion']
          break
        case 'securityDevice':
          input['initialDeploymentDate'] = this.asset.extraFields['initialDeploymentDate']
        default:
          // no extra fields
          break
      }

      const allFields = [
        'id',
        'name',
        'memo',
        'costCode',
        'serialNumber',
        'purchaseCost',
        'purchaseCurrencyCode',
        'purchaseDate',
        'status',
        'operationStatus',
        'warrantyExpires',
        'region',
        'countryIsoCode2',
        {
          name: 'model',
          type: 'Model',
          fields: [
            'id',
            'name',
            'modelNumber',
            'assetType',
            {
              name: 'manufacturer',
              type: 'Manufacturer',
              fields: ['id', 'name']
            }
          ]
        },
        {
          name: 'office',
          type: 'Location',
          fields: ['id', 'name', 'region']
        },
        {
          name: 'company',
          type: 'Company',
          fields: ['id', 'name', 'nameLocal', 'companyId']
        },
        { name: 'assignee', type: 'Assignee', fields: ['id', 'name'] },
        { name: 'primaryUser', type: 'IntraAccount', fields: ['id', 'name'] }
      ]
        .concat(this.extraFields.filter((f) => !f.fields).map((f) => f.name))
        .concat(this.extraFields.filter((f) => f.fields && !f.readOnly))
        .concat(this.readOnlyFields)

      const fields = this.getFieldsGql('read', this.currentType.graphTypeName, allFields)
      // send by form type
      this.submitting = true
      if (this.onEditMode) {
        input.id = this.id
        this.$apollo
          .mutate({
            // Query
            mutation: this.$gql`
              mutation ${this.currentType.update.mutationName}($input: ${this.currentType.update.inputType}) {
                ${this.currentType.update.mutationName}(input: $input) {
                  ${this.currentType.singleQueryName} {
                    ${fields}
                  }
                }
              }
            `,
            variables: { input }
          })
          .then(({ data }) => {
            const asset = _.get(data, `${this.currentType.update.mutationName}.${this.currentType.singleQueryName}`)
            this.setAppSnackbar({
              text: `Updated asset #${asset.id} ${asset.name} successfully.`,
              color: 'success'
            })
            this.submitting = false
            this.$router.push({
              name: 'list-assets',
              params: { updatedItem: asset }
            })
          })
          .catch((e) => {
            this.addError(null, `Failed to update asset. ${e.toString()}`, 'form')
            this.submitting = false
          })
      } else {
        this.$apollo
          .mutate({
            // Query
            mutation: this.$gql`
              mutation ${this.currentType.create.mutationName}($input: ${this.currentType.create.inputType}) {
                ${this.currentType.create.mutationName}(input: $input) {
                  ${this.currentType.singleQueryName} {
                    id
                    name
                  }
                }
              }
            `,
            variables: { input }
          })
          .then(({ data }) => {
            const asset = _.get(data, `${this.currentType.create.mutationName}.${this.currentType.singleQueryName}`)
            if (asset && asset.id && asset.name) {
              this.submitting = false
              this.setAppSnackbar({
                text: `Created asset #${asset.id} ${asset.name} successfully.`,
                color: 'success'
              })
              this.$router.push({
                name: 'list-assets',
                params: { updatedItem: asset }
              })
            } else {
              this.addError(null, 'Failed to create asset.', 'form')
            }
          })
          .catch((e) => {
            this.addError(null, `Failed to create asset. ${e.toString()}`, 'form')
            this.submitting = false
          })
      }
    },
    cancel() {
      if (history.length > 1) {
        history.go(-1)
      } else {
        this.$router.push({ name: 'list-assets' })
      }
    },
    convertDocomoSerialNumberToIccid(value) {
      let iccid = '8981100'
      if (value) {
        switch (value.substring(0, 2)) {
          case 'DN':
            iccid += '00'
            break
          case 'AX':
            iccid += '02'
            break
          case 'GD':
            iccid += '05'
            break
          default:
            // do no update serial number
            return
        }

        let part2 = value.substring(5, 14)
        if (/^\d+$/.test(part2)) {
          iccid += part2
          return iccid
        }
      }
      return null
    },
    updateExtraField(extraFieldObject) {
      if (!_.isEqual(this.asset.extraFields[extraFieldObject.name], extraFieldObject.value)) {
        this.$set(this.asset.extraFields, extraFieldObject.name, extraFieldObject.value)
      }
    },
    onCountrySelect({ iso2 }) {
      this.$set(this.asset, 'countryIsoCode2', iso2)
    },
    ...mapMutations(['setAppSnackbar'])
  },
  apollo: {
    getAsset: {
      query() {
        return this.getAssetQuery()
      },
      variables() {
        return { id: this.id }
      },
      skip() {
        this.updatePageTitle()
        return !this.id
      },
      update: () => null,
      result({ data, error }) {
        if (data && data[this.currentType.singleQueryName]) {
          const asset = data[this.currentType.singleQueryName]
          // allow edit serial number if it was empty and if user is IT Manager
          this.allowEditSerialNumber = this.isItManager || _.isEmpty(asset.serialNumber)
          this.allowEditAssignee = _.isEmpty(asset.simSlot)
          this.asset = {
            ...this.asset,
            ...{
              id: asset.id,
              office: asset.office,
              company: asset.company,
              region: this.allowedRegions.find((r) => r.text.toUpperCase() === _.get(asset, 'region', '').toUpperCase()),
              countryIsoCode2: asset.countryIsoCode2,
              name: asset.name,
              status: asset.status,
              operationStatus: asset.operationStatus,
              purchaseCurrencyCode: asset.purchaseCurrencyCode,
              purchaseCost: asset.purchaseCost,
              serialNumber: asset.serialNumber,
              memo: asset.memo,
              costCode: asset.costCode,
              model: asset.model,
              purchaseDate: asset.purchaseDate,
              warrantyExpires: asset.warrantyExpires,
              assigneeId: _.get(asset, 'assignee.id', null),
              assignee: asset.assignee,
              primaryUserId: _.get(asset, 'primaryUser.id', null),
              primaryUser: asset.primaryUser,
              manufacturerId: _.get(asset, 'model.manufacturer.id', null),
              extraFields: {}
            }
          }

          for (let ef of this.extraFields) {
            this.$set(this.asset.extraFields, ef.name, asset[ef.name])
          }
          this.$refs.form.resetValidation()
          this.updatePageTitle()
          this.$emit('name', this.asset.name)
        } else if (error) {
          if (error.toString().includes('Not found')) {
            this.renderError(404)
          }
        } else {
          this.graphQLOnError('Failed to connect to server.')
          this.asset = {}
        }
      }
    },
    locations: {
      query() {
        const fields = this.getFieldsGql('read', 'Location', ['id', 'name', 'defaultCurrencyCode', 'address', 'countryIsoCode2'])
        return this.$gql`
          query locations($where: [WhereExpression]) {
            locations(where: $where, orderBy: [{ path: "name" }]) {
              items {
                ${fields}
              }
            }
          }
        `
      },
      variables() {
        return {
          where: [
            { path: 'region', value: this.asset.region.text },
            { path: 'enabled', value: 'true' }
          ]
        }
      },
      skip() {
        return !this.asset.region || !this.ability.can('read', 'Location')
      },
      update({ locations }) {
        return locations.items
      },
      result({ error }) {
        if (error) {
          this.graphQLOnError(`Failed to get locations. ${error.toString()}`)
        }
      }
    },
    companies: {
      query() {
        const fields = this.getFieldsGql('read', 'Company', ['id', 'name', 'nameLocal', 'companyId'])
        return this.$gql`
          query companies($where: [WhereExpression]) {
            companies(where: $where, orderBy: [{ path: "name" }]) {
              items {
                ${fields}
              }
            }
          }
        `
      },
      variables() {
        return {
          where: [{ path: 'deletedExternally', value: 'false' }]
        }
      },
      skip() {
        return !this.ability.can('read', 'Company')
      },
      update({ companies }) {
        return companies.items
      },
      result({ error }) {
        if (error) {
          this.graphQLOnError(`Failed to get companies. ${error.toString()}`)
        }
      }
    }
  },
  created() {
    if (this.$route.name === 'create-asset') {
      let discoveryDevice = _.get(this.$route, 'params.discoveryDevice')
      if (discoveryDevice !== undefined) {
        this.asset.name = discoveryDevice.name
        this.asset.serialNumber = discoveryDevice.serialNumber
        this.asset.region = discoveryDevice.region
        this.asset.model = discoveryDevice.model
        this.asset.manufacturer = discoveryDevice.manufacturer
        this.asset.assignee = discoveryDevice.assignee
      }
    }
    for (let ef of this.extraFields) {
      this.$set(this.asset.extraFields, ef.name, null)
    }
  }
}
